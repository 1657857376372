import { render, staticRenderFns } from "./VisualDeskPlannerPreview.vue?vue&type=template&id=3cfa030a&"
import script from "./VisualDeskPlannerPreview.vue?vue&type=script&lang=js&"
export * from "./VisualDeskPlannerPreview.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src228135001/src/vitrueremote/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3cfa030a')) {
      api.createRecord('3cfa030a', component.options)
    } else {
      api.reload('3cfa030a', component.options)
    }
    module.hot.accept("./VisualDeskPlannerPreview.vue?vue&type=template&id=3cfa030a&", function () {
      api.rerender('3cfa030a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/deskassessment/preview/VisualDeskPlannerPreview.vue"
export default component.exports