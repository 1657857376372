<template>
  <div>
    <!-- <v-dialog persistent v-if="userEmailDialog" v-model="userEmailDialog" max-width="400px">
      <UserEmailCollection v-model="userEmailDialog" />
    </v-dialog> -->
    <sims v-model="results[pages[4].theme]" standalone />
  </div>
</template>

<script>
// import UserEmailCollection from "@/components/common/UserEmailCollection.vue";
import Sims from "@/components/deskassessment/Sims.vue";

export default {
  components: {
    // UserEmailCollection,
    Sims
  },
  data() {
    return {
      userEmailDialog: true,
      schema: require("@/assets/json/DeskAssessment/DeskAssessmentQuestions.json"),
      results: []
    };
  },
  created() {
    this.schema = require("@/assets/json/DeskAssessment/DeskAssessmentQuestions.json");

    // We need to pre-populate each key with an empty Object to allow multiple pages to bind to it
    this.results = this.schema.reduce(function(acc, item) {
      //added nesting to pain result so now there is a special case
      // where pain structure is not well captured by schema
      if (item.theme === "pain") {
        acc[item.theme] = { gender: "", areas: {} };
        return acc;
      }
      acc[item.theme] = {};
      return acc;
    }, {});
  },
  computed: {
    pages() {
      // In order to iterate through pages more easily, provide a flat list of pages
      // (without theme grouping, but with an extra theme field since this is how we know where to store our results)
      return this.schema.reduce(function(acc, item) {
        item.pages.forEach(page => acc.push({ theme: item.theme, page: page }));
        return acc;
      }, []);
    }
  }
};
</script>
